import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { CONVERSATION } from 'shared/router';

function Index() {

    const sidebarItems = [
        { name: 'Chat', location: CONVERSATION.CHAT },
        { name: 'Meetings', location: CONVERSATION.MEETING_MANAGEMENT },
    ];

    const location = useLocation();
    const [currentTab, setCurrentTab] = useState();

    useEffect(() => {
        const currentSidebarItem = sidebarItems.find(item => item.location === location.pathname);
        if (currentSidebarItem) {
            setCurrentTab(currentSidebarItem.name);
        } else {
            setCurrentTab(undefined);
        }
    }, [location]);


    return (
        <div className='flex flex-col items-center h-full border'>
            <div>
                {/* Chat */}
                <div className='flex items-center gap-2 mt-20 cursor-pointer'>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M29.3337 8.33464V15.1346C29.3337 16.828 28.7737 18.2546 27.7737 19.2413C26.787 20.2413 25.3603 20.8013 23.667 20.8013V23.2146C23.667 24.1213 22.6537 24.668 21.907 24.1613L20.6137 23.308C20.7337 22.8946 20.787 22.4413 20.787 21.9613V16.5346C20.787 13.8146 18.9737 12.0013 16.2537 12.0013H7.20033C7.01366 12.0013 6.84033 12.0146 6.66699 12.028V8.33464C6.66699 4.93464 8.93366 2.66797 12.3337 2.66797H23.667C27.067 2.66797 29.3337 4.93464 29.3337 8.33464Z" stroke={`${currentTab === "Chat" ? '#F83758' : 'black'}`} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M20.787 16.5333V21.96C20.787 22.44 20.7337 22.8933 20.6137 23.3067C20.1203 25.2667 18.4937 26.4933 16.2537 26.4933H12.627L8.60033 29.1733C8.46534 29.2657 8.30761 29.3194 8.14428 29.3284C7.98095 29.3374 7.81827 29.3015 7.67393 29.2245C7.5296 29.1475 7.40913 29.0324 7.32563 28.8918C7.24213 28.7511 7.19879 28.5902 7.20033 28.4267V26.4933C5.84033 26.4933 4.70699 26.04 3.92033 25.2533C3.12033 24.4533 2.66699 23.32 2.66699 21.96V16.5333C2.66699 14 4.24033 12.2533 6.66699 12.0267C6.84033 12.0133 7.01366 12 7.20033 12H16.2537C18.9737 12 20.787 13.8133 20.787 16.5333V16.5333Z" stroke={`${currentTab === "Chat" ? '#F83758' : 'black'}`} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <Link to={sidebarItems[0].location} onClick={(e) => !sidebarItems[0].location && e.preventDefault()}>
                        <p className={` md:text-sm lg:text-2xl font-bold ${currentTab === 'Chat' ? "text-[#F83758]" : "text-black"}`} >Chat</p>
                    </Link>
                </div>
                {/* Meeting */}
                {/* <div className='flex items-center gap-2 mt-12 cursor-pointer'>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.6266 19.2546C24.4533 19.5613 26.4666 19.2413 27.8799 18.2946C29.7599 17.0413 29.7599 14.988 27.8799 13.7346C26.4533 12.788 24.4133 12.468 22.5866 12.788M9.33326 19.2546C7.50659 19.5613 5.49326 19.2413 4.07992 18.2946C2.19992 17.0413 2.19992 14.988 4.07992 13.7346C5.50659 12.788 7.54659 12.468 9.37326 12.788M23.9999 9.54797C23.916 9.53463 23.8305 9.53463 23.7466 9.54797C22.8568 9.51579 22.0143 9.13891 21.3973 8.49699C20.7802 7.85506 20.4369 6.99837 20.4399 6.10797C20.4399 4.2013 21.9733 2.66797 23.8799 2.66797C24.7923 2.66797 25.6672 3.0304 26.3124 3.67552C26.9575 4.32065 27.3199 5.19562 27.3199 6.10797C27.3175 6.999 26.9706 7.85458 26.3518 8.49572C25.733 9.13686 24.8903 9.5139 23.9999 9.54797V9.54797ZM7.95992 9.54797C8.03992 9.53464 8.13326 9.53464 8.21326 9.54797C9.10308 9.51579 9.94553 9.13891 10.5626 8.49699C11.1796 7.85506 11.5229 6.99837 11.5199 6.10797C11.5199 4.2013 9.98659 2.66797 8.07992 2.66797C7.16758 2.66797 6.2926 3.0304 5.64747 3.67552C5.00235 4.32065 4.63992 5.19562 4.63992 6.10797C4.65326 7.97464 6.11992 9.4813 7.95992 9.54797ZM15.9999 19.508C15.916 19.4946 15.8305 19.4946 15.7466 19.508C14.8568 19.4758 14.0143 19.0989 13.3973 18.457C12.7802 17.8151 12.4369 16.9584 12.4399 16.068C12.4399 14.1613 13.9733 12.628 15.8799 12.628C16.7923 12.628 17.6672 12.9904 18.3124 13.6355C18.9575 14.2806 19.3199 15.1556 19.3199 16.068C19.3066 17.9346 17.8399 19.4546 15.9999 19.508ZM12.1199 23.708C10.2399 24.9613 10.2399 27.0146 12.1199 28.268C14.2533 29.6946 17.7466 29.6946 19.8799 28.268C21.7599 27.0146 21.7599 24.9613 19.8799 23.708C17.7599 22.2946 14.2533 22.2946 12.1199 23.708Z" stroke={`${currentTab === "Meetings" ? '#F83758' : 'black'}`} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <Link to={sidebarItems[1].location} onClick={(e) => !sidebarItems[1].location && e.preventDefault()}>
                        <p className={` md:text-sm lg:text-2xl font-bold ${currentTab === 'Meetings' ? "text-[#F83758]" : "text-black"}`} >Meeting</p>
                    </Link>
                </div> */}
            </div>
        </div>

    )
}

export default React.memo(Index)