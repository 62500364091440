// Library imports
import React, { useState, useEffect, Suspense, lazy } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

// APIS
import { checkPlanExpiry, expiryCoin, getReferLink } from 'apis/home'

// Components
import { LazyImage } from 'components/image';
import UserProfileCard from "./components/userProfileCard"
import FeaturedEvents from "./components/featuredEvents"
import { updateLocation } from 'apis/matches'



const Category = lazy(() => import('./components/category'))
const TimeBasedEvent = lazy(() => import('./components/timeBasedEvent'))
const PlanUpgrade = lazy(() => import('components/popUps').then(module => ({ default: module.PlanUpgrade })));
const ToVerifyAccount = lazy(() => import("components/popUps").then(module => ({ default: module.ToVerifyAccount })))
const ConnectPlanPopUp = lazy(() => import("components/popUps").then(module => ({ default: module.ConnectPlanPopUp })))
const LocationError = lazy(() => import("components/popUps").then(module => ({ default: module.LocationTurnoff })))
const refrelImg = `${process.env.REACT_APP_CLOUDINARY_IMAGE_BASEURL_V1}CoupleSquad Static Assets/Home/lkl5m04npnylumquiuxn`

function Index() {
    const location = useLocation();
    const userInfo = useSelector((state) => state.userProfile);
    const { currentStep, isGuide } = useSelector((state) => state.guide);
    const [planExpire, setPlanExpire] = useState(false)
    const [showPlan, setShowPlan] = useState(false)
    const [errorType, setErrorType] = useState(false)

    const [showVerificationPopUp, setShowVerificationPopUp] = useState(false)



    const handlePlanExpire = async () => {
        const res = await expiryCoin()
        if (res?.data?.expired) {
            setPlanExpire(res?.data?.expired)
        }
    }

    const handleReferee = async () => {
        const res = await getReferLink()
        if (res?.data) {
            if (!navigator.clipboard) {
                toast.error("Clipboard API not supported in this browser.");
            } else {
                toast.success('Invitation link copied to clipboard')
                await navigator.clipboard.writeText(res?.data);
            }
        }
    }

    const getLocation = async () => {
        if (!navigator.geolocation) {
            setErrorType('location_error');
            toast('Geolocation is not supported by your browser');
            return;
        }
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const res = await updateLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                });
                if (res?.success) setErrorType(false)
            },
            () => {
                setErrorType('location_error');
            }
        );
    };

    useEffect(() => {
        handleShowPlanSuggestionPopup();
        checkPlanExpiry();
        handlePlanExpire();
        handleShowVerificationPopUp();
        getLocation()
    }, []);




    const handleShowVerificationPopUp = () => {
        const prevPath = location.state?.from;
        if (prevPath === '/login') {
            if (userInfo?.verification?.status === 'pending') {
                setShowVerificationPopUp(true)
            }
        }
    }

    const handleShowPlanSuggestionPopup = () => {
        const prevPath = location.state?.from;
        if (prevPath === '/login') {
            if (userInfo?.wallet?.current_plan?.name === 'FREE') setShowPlan(true)
        }
    }



    // currentStep
    return (
        <div className="flex flex-col h-full bg-[#f3f3f3]">

            {!isGuide &&
                <>
                    {planExpire &&
                        <div className="absolute inset-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-75">
                            <Suspense children={<PlanUpgrade closeBtn={() => setPlanExpire(false)} />} fallback={<div>loading...</div>} />
                        </div>
                    }
                    {showVerificationPopUp &&
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
                            <div className="relative">
                                <Suspense children={<ToVerifyAccount closeBtn={() => setShowVerificationPopUp(false)} />} fallback={<div>loading...</div>} />
                            </div>
                        </div>
                    }
                    {showPlan &&
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
                            <div className="relative w-full md:w-[60%] rounded-3xl">
                                <Suspense children={<ConnectPlanPopUp close={() => setShowPlan(false)} />} fallback={<div>loading...</div>} />
                            </div>
                        </div>
                    }
                    {errorType && (
                        <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center w-full bg-black bg-opacity-75">
                            <Suspense fallback={<div>Loading...</div>}>
                                <LocationError closeBtn={() => setErrorType(false)} />
                            </Suspense>
                        </div>
                    )}
                </>
            }


            <div className="w-[84%] mx-auto mt-10 flex flex-col md:flex-row justify-between gap-6">
                {/* User Profile Card */}
                <div className="w-full md:w-[22%]">
                    <UserProfileCard />
                </div>

                {/* Featured Events */}
                <div className="w-full md:w-[76%]">
                    <FeaturedEvents />
                </div>
            </div>


            {/* category Based Events */}
            <Suspense children={
                <div className='mt-16 mb-10'>
                    <Category />
                </div>
            }
                fallback={<div>loading....</div>}
            />

            {/* TimeBasedEvent Based Events */}
            <Suspense children={<TimeBasedEvent />} fallback={<div>loading....</div>} />

            {/* Invitation Section */}
            <div className="flex flex-col items-center w-full">
                <div className='w-[84%] ms-[34px] h-full mb-16 relative'> {/* Add relative here */}
                    {/* Responsive Absolute positioned Invite Button */}
                    <button className="z-50 absolute top-[48%] left-[4%] md:top-[48%] md:left-[4%] lg:top-[185px] lg:left-[55px] px-1 py-1 sm:px-2 sm:py-1 md:px-5 md:py-2 lg:px-5 lg:py-2 bg-brandRed text-white text-xs md:text-md lg:text-lg" onClick={handleReferee}>
                        Invite
                    </button>

                    <LazyImage src={refrelImg} className="w-full h-full" />
                </div>
            </div>

        </div >
    )
}

export default Index