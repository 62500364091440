import { noAuth } from "apis";


export const getUpcomingEvents = async () => {
    const res = await noAuth({
        method: 'GET',
        url: `/events/upcoming?limit=8`
    });
    return res;
}

export const getFeaturedEvents = async (page = 1) => {
    const res = await noAuth({
        method: 'GET',
        url: `/events/featured?page=${page}&limit=8`
    });
    return res;
}

export const getEventsByTagsUnAuth = async (page, data) => {
    const res = await noAuth({
        method: 'POST',
        url: `/events/tags?page=${page}&limit=8`,
        data
    });
    return res;
}