import React, { useEffect, useState } from "react";
import { fetchPlanValidity, getUserDetailsForHome } from "apis/home";

import currentPlan from 'assets/home/icons/profileSection/currentPlan.svg'
import coins from 'assets/home/icons/profileSection/coins.svg'
import affinityScore from 'assets/home/icons/profileSection/affinityScore.svg'
import meeting from 'assets/home/icons/profileSection/meeting.svg'
import wallet from 'assets/home/icons/profileSection/wallet.svg'
import gps from 'assets/home/icons/profileSection/gps.svg'
import scan from 'assets/home/icons/profileSection/scan.svg'

import { Link } from "react-router-dom";
import { CONVERSATION, GPS, WALLET } from "shared/router";


function Index() {

    const [profileInfo, setProfileInfo] = useState()
    const [planValidTill, setPlanValidTill] = useState()

    const profileDetails = async () => {
        const res = await getUserDetailsForHome()
        if (res?.success) setProfileInfo(res?.data)
    }

    const getPlanValidity = async () => {
        const res = await fetchPlanValidity()
        if (res?.success) setPlanValidTill(res?.data)
    }

    useEffect(() => {
        profileDetails()
        getPlanValidity()
    }, [])

    const details = [
        {
            label: "Plan",
            value: profileInfo?.wallet?.current_plan,
            icon: currentPlan,
        },
        {
            label: "Coins",
            value: profileInfo?.wallet?.balance,
            icon: coins,
        },
        {
            label: "Affinity Score",
            value: profileInfo?.affinity_score,
            icon: affinityScore,
        },
    ];

    const features = [
        {
            label: "Meeting",
            value: 'Schedule Meeting',
            icon: meeting,
            link: CONVERSATION.MEETING_SLOTS,
        },
        {
            label: "My Wallet",
            value: profileInfo?.wallet?.balance,
            icon: wallet,
            link: WALLET.WALLET,
        },
        {
            label: "GPS",
            value: 'Find Nearby',
            icon: gps,
            link: GPS.GPS,
        },
        {
            label: "Scan",
            value: 'QR Code',
            icon: scan,
            link: WALLET.SCAN,
        },
    ];



    return (
        <>
            <div className="w-full p-4 mx-auto bg-white shadow-lg rounded-2xl">
                {/* Profile Section */}
                <div className="flex flex-col items-center">
                    <div className="">
                        <img
                            src={profileInfo?.photo}
                            alt="Profile"
                            className="w-24 h-24 rounded-full"
                        />
                    </div>

                    <p className="my-2 font-semibold">{profileInfo?.name}</p>
                    <p className="text-sm text-center text-subText">
                        {planValidTill === 'plan_expired' ? 'You do not have any active plan' : `Your plan is valid till ${planValidTill}`}
                    </p>
                </div>

                {/* Details Section */}
                <div className="mt-6 space-y-4">
                    {details.map((detail, index) => (
                        <div key={index}>
                            <div className="flex items-center gap-x-2">
                                <img src={detail.icon} alt={detail.label} className="w-10 h-10" />
                                <span className="">
                                    <span className="text-subText">{detail.label}</span>
                                    <p className="font-medium">{detail.value}</p>
                                </span>
                            </div>
                            {index < details.length - 1 && (
                                <div className="w-full h-[1px] my-1 bg-gray-400"></div>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            <div className="mt-5 space-y-4">
                {features?.map((item, index) => (
                    <Link to={item?.link} className="flex items-center justify-between p-4 bg-white rounded-lg shadow-sm">
                        <div className="flex items-center gap-4">
                            <img src={item.icon} alt="" />
                            <div>
                                <p className="text-sm text-gray-500">{item?.label}</p>
                                <p className="font-medium text-gray-800">{item?.value}</p>
                            </div>
                        </div>
                        <span className='font-bold'>↗</span>
                    </Link>
                ))}
            </div>
        </>
    );
};

export default Index;
