import { useEffect, useRef } from 'react';
import { socket } from '../socket/SocketManager';

export const useSocketListenerWithConnectedSocket = (eventHandlers) => {
    const eventHandlersRef = useRef(eventHandlers);

    useEffect(() => {
        eventHandlersRef.current = eventHandlers;
    }, [eventHandlers]);

    useEffect(() => {
        if (socket) {
            const handlers = Object.entries(eventHandlersRef.current);

            handlers?.forEach(([eventName, handler]) => {
                socket.on(eventName, handler);
            });

            return () => {
                handlers?.forEach(([eventName, handler]) => {
                    socket.off(eventName, handler);
                });
            };
        }
    }, []);
};